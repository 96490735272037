import React from "react";
import PropTypes from "prop-types";

import { useStaticQuery, graphql } from "gatsby";

import { Row, Col } from "react-bootstrap";

import ServiceItem from "components/ServiceItem";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";

const Marketing = ({ className }) => {
  const { markdownRemark = {} } = useStaticQuery(graphql`
    query MarketingQuery {
      markdownRemark(fields: { fileName: { regex: "/marketing/i" } }) {
        frontmatter {
          anchor
          header
          subheader
          marketing {
            id
            content
            header
            iconName
          }
        }
      }
    }
  `);

  const frontmatter = markdownRemark.frontmatter;
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, subheader: rootSubHeader, marketing } = frontmatter;

  return (
    <PageSection className={className} id={anchor}>
      <Row>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
      </Row>
      <Row className="text-center">
        {marketing.map(({ id, header, content, iconName }) => (
          <Col md={4} key={id}>
            <ServiceItem iconName={iconName} header={header} content={content} />
          </Col>
        ))}
      </Row>
    </PageSection>
  );
};

Marketing.propTypes = {
  className: PropTypes.string,
};

Marketing.defaultProps = {
  className: null,
};

export default Marketing;
