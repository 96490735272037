import React from "react";
import PropTypes from "prop-types";
import spriteSrc from "content/assets/images/sprite.svg";

const Icon = ({ name, className, visible }) => (
  <svg className={`icon icon-${name}  ${className} ${!visible ? "hidden" : ""}`}>
    <use xlinkHref={`${spriteSrc}#icon-${name}`} />
  </svg>
);

Icon.defaultProps = {
  name: "",
  className: "",
  visible: true,
};

Icon.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  visible: PropTypes.bool,
};
export default Icon;
