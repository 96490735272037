import React from "react";
import PropTypes from "prop-types";

import { StaticQuery, graphql } from "gatsby";
// import Img from "gatsby-image";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

const Image = ({ fileName, alt, ...restProps }) => (
  <StaticQuery
    query={graphql`
      query ImageQuery {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const image = data.images.edges.find((n) => n.node.relativePath.includes(fileName));
      if (!image) {
        return null;
      }
      const imageData = getImage(image.node.childImageSharp.gatsbyImageData);
      return <GatsbyImage image={imageData} alt={alt} {...restProps} />;
    }}
  />
);

// Image.propTypes = {
//   fileName: PropTypes.string.isRequired,
//   alt: PropTypes.string,
// };

// Image.defaultProps = {
//   alt: null,
// };

export default Image;
