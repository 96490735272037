import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Button } from "react-bootstrap";
import clsx from "clsx";
import useSmoothScrollTo from "../../hooks/useSmoothScrollTo";
import "./style.scss";
import AnimLights from "views/AnimLights/AnimLights";

const Top = () => {
  const { markdownRemark = {} } = useStaticQuery(
    graphql`
      query TopQuery {
        markdownRemark(fileAbsolutePath: { regex: "/top/i" }) {
          frontmatter {
            header
            jumpToAnchor
            jumpToAnchorText
          }
        }
      }
    `,
  );

  const frontmatter = markdownRemark.frontmatter;
  if (!frontmatter) {
    return null;
  }
  const { header, jumpToAnchor, jumpToAnchorText } = frontmatter;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const scrollToSection = useSmoothScrollTo(jumpToAnchor);
  let extraInfoPart;
  if (jumpToAnchor && jumpToAnchorText) {
    extraInfoPart = (
      <Button
        size="xl"
        variant="primary"
        className="text-uppercase hero__btn"
        onClick={scrollToSection}
      >
        {jumpToAnchorText}
      </Button>
    );
  }

  return (
    <div className={clsx("text-white text-center hero")}>
      <div id="app" />
      <Container>
        <div className="intro-text">
          <div className="intro-heading text-uppercase">{header}</div>
          {extraInfoPart}
        </div>
      </Container>
    </div>
  );
};

export default Top;
